import React, { useState, useEffect } from 'react';
import {Image, SlideRowbox, SlideInnerbox, Span} from '../../style/style';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import img1 from './images/img01.jpeg';
import img2 from './images/img02.jpeg';
import img3 from './images/img03.jpeg';
import img4 from './images/img04.jpeg';
import img5 from './images/img05.jpeg';
import img6 from './images/img06.jpeg';
import img7 from './images/img07.jpeg';
import img8 from './images/img08.jpeg';
import img9 from './images/img09.jpeg';
import img10 from './images/img10.jpeg';
import img11 from './images/img11.jpeg';
import img12 from './images/img12.jpeg';
import img13 from './images/img13.jpeg';
import img14 from './images/img14.jpeg';
import img15 from './images/img15.jpeg';


export const Slideshow = () => {
    const [allImages] = useState([img1, img2, img3, img4, img5, img6,img7, img8, img9, img10, img11, img12, img13, img14, img15]);
    const [currentImage, setCurrentImage] = useState(allImages[10]);
    const [nextImage, setNextImage] = useState(10);

    useEffect(() => {

        setCurrentImage(allImages[nextImage])

    }, [allImages, nextImage]); 

    const setPreviousImage = () => {
        if (nextImage === 0) {
            setNextImage(14);
        } else {
            setNextImage(nextImage - 1);
        }
    }

    const setFollowingImage = () => {
        if (nextImage === 14) {
            setNextImage(0);
        } else {
            setNextImage(nextImage + 1);
        }
    }

    return (
            <SlideRowbox>
                <Span onClick={() => setPreviousImage()}>
                    <AiOutlineLeft size={60}/>
                </Span>
                <SlideInnerbox>
                    <Image src={currentImage}>
                    </Image>
                </SlideInnerbox>
                <Span onClick={() => setFollowingImage()}>
                    <AiOutlineRight size={60}/>
                </Span>
            </SlideRowbox>
    );
}
