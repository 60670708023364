import { useState, useEffect} from 'react';
import { Form, Inputbox, Input, Label, Button, Textarea, Messagesentbox, ContactColumnbox, Columnbox, Rowbox } from '../style/style';
import axios from 'axios';

export const Contact = () => {
    const [name, setName] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState();
    const [messagesent, setMessagesent] = useState();
    const [messagenotsent, setMessagenotsent] = useState();

    const handleClick = (e) => {
        e.preventDefault();      
        console.log(content);

        axios({
            method: "POST",
            url:"http://localhost:3002/send",
            data: content 
            }).then((response)=>{
            if (response.data.status === 'success') {
                setMessagesent(true)
                resetForm()
            } else if (response.data.status === 'fail') {
                setMessagenotsent(true)
            }
            })
        }
        
    useEffect(() => {
        setContent({name: name, phonenumber: phonenumber, email: email, message: message})
    }, [name, phonenumber, email, message]);

    const resetForm = () => {
        setName('');
        setPhonenumber('');
        setEmail('');
        setMessage('');
    }

    return (
        <ContactColumnbox>
            <Form onSubmit={handleClick}>
                <Inputbox>
                    <Label>
                        Uw naam:
                    </Label>
                    <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            >
                    </Input>
                </Inputbox>
                <Inputbox>
                    <Label>
                        Uw telefoonnummer:
                    </Label>
                    <Input
                            value={phonenumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                    >
                    </Input>
                </Inputbox>
                <Inputbox>
                    <Label>
                        Uw e-mailadres:
                    </Label>
                    <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                    >
                    </Input>
                </Inputbox>
                <Inputbox>
                    <Label>
                        Uw vraag:
                    </Label>
                    <Textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                    >
                    </Textarea>
                </Inputbox>
                    <Rowbox>
                        <Button type="submit">
                            Verzend bericht
                        </Button>
                    </Rowbox>
                <Columnbox>
                    <Messagesentbox>
                            {messagesent ? 'Uw bericht is verzonden.' : ''}
                            {messagenotsent ? 'Uw bericht is niet verzonden. U kunt uw bericht het beste vanuit uw e-mail programma sturen naar het volgende adres: steak-wagen@gmail.com.' : ''}
                    </Messagesentbox>
                </Columnbox>
            </Form>
        </ContactColumnbox>
    );
}
