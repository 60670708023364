import { ProfileRowbox, Paragraph, ProfileImage, ProfileColumnbox } from '../style/style';
import Bert from '../images/Bert.jpg';

export const Oversteakwagen = () => {

    return (
    <ProfileRowbox>
        <ProfileImage src={ Bert }>
        </ProfileImage>
        <ProfileColumnbox>
            <Paragraph>
                Steakwagen is ontstaan zonder plan en zonder doel. Puur en alleen vanuit de passie voor bbqen en alles wat met houtskool koken te maken heeft. En dat maakt ook de charme. Omdat steakwagen niet vanuit een commercieel oogpunt is begonnen doen we dingen die we leuk vinden en dat proef je terug.      
            </Paragraph>
            <Paragraph>
                Ik ben Bert en ben inmiddels al meer dan 15 jaar Chef-kok bij een restaurant in Hilversum. Recentelijk is dit restaurant helemaal verbouwd tot een barbecue restaurant onder de naam: Roast bar&grill. We staan hier in een mooie open keuken alle gerechten mee te geven vanaf Big Green Eggs. Dit zijn zogeheten kamado bbq’s, gesloten bbq’s van keramiek.          
            </Paragraph>
            <Paragraph>
                Samen met Reinder mijn bbq broeder, die in het dagelijks leven projectleider is hebben we onze smoker aangeschaft. Hij is volledig nieuw gebouwd en is 4 meter lang en ruim 2 meter hoog.
            </Paragraph>
            <Paragraph>
                Naast kleine cateringklussen met Big Green Eggs kunnen we met Steakwagen nu ook mooie grote partijen cateren. En eerlijk is eerlijk, er staat natuurlijk wel wat als er een grote smoker op je catering staat, naast dat het vlees wat uit de smoker komt een ongekend mooie rooksmaak meekrijgt.
            </Paragraph>
            <Paragraph>
                Elke catering die we draaien is voor ons maatwerk. Reinder en ik houden allebei van het persoonlijke contact en vinden het prettig om samen tot een mooi samengesteld geheel te komen. Dit op basis van budget en natuurlijk persoonlijke wensen. Op onze website zie je dus ook geen uitgebreide prijslijsten maar wel een mobiel nummer en e-mailadres om ons te contacten. En verder foto’s die volgens ons een mooi beeld geven van wat we doen.
            </Paragraph>
        </ProfileColumnbox>
    </ProfileRowbox>
    );
}
