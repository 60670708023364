import Steakwagen from './page/steak-wagen';

function App() {

  document.body.style.backgroundImage = "linear-gradient(to bottom, black, black)";
  //document.body.style.backgroundImage = `url(${Background})`;

  return (
      <Steakwagen>
      </Steakwagen>
  );
}

export default App;
