import React, { useState } from 'react';
import { Navbox, Textbox, Columnbox, Rowbox, Footer, FooterText } from '../style/style';
import { Oversteakwagen } from '../components/oversteakwagen';
import { Impressie } from '../components/impressie';
import { Contact } from '../components/contact';
import {PageTitle} from "../components/sub/pagetitle";

function Steakwagen() {

    const [oversteakwagen, setOversteakwagen] = useState(true);
    const [impressie, setImpressie] = useState(false);
    const [contact, setContact] = useState(false);

    const setFirstComponent = () => {
        setOversteakwagen(true);
        setImpressie(false);
        setContact(false)
    }

    const setSecondComponent = () => {
        setOversteakwagen(false);
        setImpressie(true);
        setContact(false)
    }
    
    const setThirdComponent = () => {
        setOversteakwagen(false);
        setImpressie(false);
        setContact(true)
    }

    return (
            <Columnbox>
                <PageTitle/>
                <Navbox>
                    <Textbox onClick={() => setFirstComponent()}>
                        Over Steak-wagen
                    </Textbox>
                    <Textbox onClick={() => setSecondComponent()}>
                        Impressie
                    </Textbox>
                    <Textbox onClick={() => setThirdComponent()}>
                        Contact
                    </Textbox>
                </Navbox>
                <Rowbox>
                    {oversteakwagen ? <Oversteakwagen> </Oversteakwagen> : ""}
                    {impressie ? <Impressie> </Impressie> : ""}
                    {contact ? <Contact> </Contact> : ""}
                </Rowbox>
                <Footer>
                    <FooterText>
                        Bank: NL88 KNAB 0417 3867 02
                    </FooterText>
                    <FooterText>
                        BTW ID: NL004146127B54
                    </FooterText>
                    <FooterText>
                        KVK: 85776467
                    </FooterText>
                    <FooterText>
                        Mobiel: 06-54 72 41 96
                    </FooterText>
                    <FooterText>
                        © Steak-wagen 2022
                    </FooterText>
                </Footer>
            </Columnbox>
        );
    }

export default Steakwagen;
