import styled from 'styled-components';

export const Rowbox = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
      @media (max-width: 768px) {

      }
`;

export const Columnbox = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
        @media (max-width: 768px) {

      }
`;

export const ProfileRowbox = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 75vw;
      @media (max-width: 768px) {
        width: 90vw;
        flex-flow: column nowrap;
      }
`;

export const ProfileColumnbox = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-left: 5vw;
        @media (max-width: 768px) {
         margin-left: 0vw;
      }
`;

export const SlideRowbox = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    height: 45vh;
    width: 90vw;
    margin-top: 3vh;
      @media (max-width: 768px) {
        width: 100vw;
      }
`;

export const SlideInnerbox = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 40vh;
    width: 65vw;
      @media (max-width: 768px) {
        width: 80vw;
      }
`;

export const ContactRowbox = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
      @media (max-width: 768px) {

      }
`;

export const ContactColumnbox = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 48vh;
        @media (max-width: 768px) {

      }
`;

export const Title = styled.div`
    font: Arial, sans-serif;
    color: #c79a25;
    text-shadow: 2px 2px 4px #000000;
    opacity: 0.9;
    margin-top: 25px;
    font-size: 100px;
        @media (max-width: 768px) {
            font-size: 50px;
      }
`;

export const Navbox = styled.nav`
    display: flex;
    flex-flow: row nowrap;

    width: 90vw;
    height: 4vh;
    border-radius: 5px;
    margin-top: 25px;
    border: 5px solid #c79a25;
    border-width: 2px;
        @media (max-width: 768px) {
            flex-direction: row;
      }
`;

export const Paragraph = styled.p`
    font: 15px Arial, sans-serif;
    color: #c79a25;
        @media (max-width: 768px) {
        flex-direction: column;
        }
`;

export const Image = styled.img`
    border-radius: 5px;
    height: 35vh;
        @media (max-width: 768px) {
            width: 80vw;
            height: 40vh;
        }
`;

export const ProfileImage = styled.img`
    border-radius: 5px;
    height: 420px;
    margin-top: 2vh;
    @media (max-width: 768px) {
        height: 300px;
        }
`;

export const Textbox = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #c79a25;
    font: 15px Arial, sans-serif;
    margin-left: 12vw;
    margin-right: 12vw;
    &:hover {
        cursor: pointer;
        color: #8a6b1d;
    }
    @media (max-width: 768px) {
            flex-direction: row;
            margin-left: 3vw;
            margin-right: 3vw;

        }
`;

export const Form = styled.form`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 5px;
    margin-top: 2vh;
`;

export const Inputbox = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: left;
    margin-top: 1vh;
`;

export const Label = styled.label`
    font: 15px Arial, sans-serif;
    border-radius: 5px;
    color: #c79a25;
`;

export const Input = styled.input`
    border-radius: 5px;
    background-color: #f3f3f3;
    border-color: lightgray;
    border-width: 2px;
    width: 25vw;
    @media (max-width: 768px) {
            width: 88vw;
        }
`;

export const Textarea = styled.textarea`
    border-radius: 5px;
    background-color: #f3f3f3;
    border-width: 2px;
    border-right-color: lightgray;
    border-bottom-color: lightgray;
    height: 20vh;
    width: 25vw;
    @media (max-width: 768px) {
        height: 10vh;
        width: 88vw;
        }
`;

export const Button = styled.button`
    border-radius: 5px;
    height: 3vh;
    width: 10vw;
    margin-top: 1vh;
    background-color: #c79a25;
    border-color: #c79a25;
    @media (max-width: 768px) {
        height: 4vh;
        width: 40vw;
        }
`;

export const Messagesentbox = styled.p`
    color: black;
    width: 50vw;
    margin-top: 2vw;
    text-align: center;
`;

export const Section = styled.section`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    background: #541d82;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    box-shadow: 5px 5px 6px #551d829c;
`;

export const Span = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    color: #c79a25;
    &:hover {
        color: #8a6b1d;
        cursor: pointer;
        }

    @media (max-width: 768px) {
            width: 10vw;
        }
`;

export const FooterText = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #c79a25;
    font: 15px Arial, sans-serif;
    @media (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 5vw;
            margin-right: 5vw;
        }
`;

export const Footer = styled.nav`
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    width: 90vw;
    height: 4vh;
    border-radius: 5px;
    margin-top: 3vh;
    border: 5px solid #c79a25;
    border-width: 2px;
        @media (max-width: 768px) {
            height: 20vh;
            flex-flow: column;
            align-items: center;
            justify-content: space-between;
            margin-top: 0vh;
      }
`;

